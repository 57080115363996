@import "variables";
@import '~bootstrap/scss/bootstrap.scss';
@import 'common';
@import 'fonts';

:export {
  primary: $primary;
  primaryLight: $primaryLight;
  secondary: $secondary;
  tertiary: $tertiary;
}
