@font-face {
  font-family: 'shorelines_script';
  src: url('./fonts/shorelines_script_bold-webfont.woff2') format('woff2'),
  url('./fonts/shorelines_script_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald-DemiBold.woff2') format('woff2'),
  url('./fonts/Oswald-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
